import React, { useState } from 'react';
import Navbar from './Navbar';
import AdminSidebar from './AdminSidebar';
import axios from 'axios';
import '../styles/Adminfile.css';

function Adminfile() {
    const [file, setFile] = useState(null); 
    const [isUploading, setIsUploading] = useState(false); 
    const [isSearching, setIsSearching] = useState(false); 
    const [message, setMessage] = useState(''); 
    const [position, setPosition] = useState(''); 
    const [location, setLocation] = useState(''); 
    const [searchResults, setSearchResults] = useState([]); 

    // Handle file selection
    const handleFileChange = (e) => {
        setFile(e.target.files[0]); // Set the selected file
    };

    // Handle file upload process
    const handleUpload = async () => {
        if (!file) {
            alert('Please select a file first.');
            return;
        }

        setIsUploading(true); // Set loading state for uploading
        setMessage('Processing...'); // Show "Processing..." while uploading

        const formData = new FormData(); // Create a FormData object
        formData.append('file', file); // Append the selected file to FormData

        try {
            // Send POST request to the backend with the file
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/file/addfile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setMessage('File uploaded and processed successfully!'); // Success message
        } catch (error) {
            setMessage(error.response?.data?.message || 'Error uploading file, please try again.'); // Error message
        } finally {
            setIsUploading(false); // Reset uploading state
        }
    };

    // Handle search functionality
    const handleSearch = async () => {
        if (!position && !location) {
            alert('Please provide a position or location to search.');
            return;
        }
    
        setIsSearching(true); // Show loading while searching
        setMessage('Searching...');
    
        try {
            // Make the search request using fetch
            const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/file/search`, {
                method: 'POST', // Use POST method
                headers: {
                    'Content-Type': 'application/json', // Ensure the content type is JSON
                },
                body: JSON.stringify({
                    position,
                    location,
                }), 
            });
    
            if (!response.ok) {
                throw new Error('Error searching records, please try again.');
            }
    
            const data = await response.json(); // Parse the response as JSON
            setSearchResults(data); // Set the search results
            setMessage('Search completed successfully!');
        } catch (error) {
            setMessage(error.message);
        } finally {
            setIsSearching(false); // Stop loading
        }
    };

    return (
        <div className="MainContainer">
            <div style={{ height: "70px" }}>
                <Navbar />
            </div>
            <hr />
            <div className="container second-main">
                <div className="row">
                    <div className="col-1 col-lg-3 sidebarDiv">
                        <AdminSidebar />
                    </div>
                    <div className="col-9">
                        {/* File input for selecting CSV or Excel files */}
                        <input type="file" onChange={handleFileChange} accept=".csv,.xlsx" />
                        {/* Upload button */}
                        <button onClick={handleUpload} disabled={isUploading}>
                            {isUploading ? 'Uploading...' : 'Upload File'}
                        </button>
                        {/* Feedback message */}
                        {message && <p>{message}</p>}

                        {/* Search Section */}
                        <div className="search-section">
                            <h3>Search by Position and Location</h3>
                            <input
                                type="text"
                                placeholder="Position"
                                value={position}
                                onChange={(e) => setPosition(e.target.value)}
                            />
                            <input
                                type="text"
                                placeholder="Location"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                            />
                            <button onClick={handleSearch} disabled={isSearching}>
                                {isSearching ? 'Searching...' : 'Search'}
                            </button>
                        </div>

                        {/* Display total count of search results */}
                        {searchResults.length > 0 && (
                            <div className="result-count">
                                <p>Total Results: {searchResults.length}</p>
                            </div>
                        )}

                        {/* Display search results in a table */}
                        {searchResults.length > 0 && (
                            <table className="search-results-table">
                                <thead>
                                    <tr>
                                        <th style={{ width: '20%' }}>Position</th>
                                        <th style={{ width: '20%' }}>Location</th>
                                        <th style={{ width: '20%' }}>Email</th>
                                        <th style={{ width: '40%' }}>LinkedIn URL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {searchResults.map((result, index) => (
                                        <tr key={index}>
                                            <td>{result.position}</td>
                                            <td>{result.city}, {result.state}</td>
                                            <td>{result.email}</td>
                                            <td>
                                                <a href={`https://${result.linkedInUrl}`} target="_blank" rel="noopener noreferrer">
                                                    {result.linkedInUrl}
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
            <div className="dashboard-footer text-center body-font-4 text-gray-500">
                Copyright © 2024 Neverhunt All rights reserved.
            </div>
        </div>
    );
}

export default Adminfile;
