import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Jobsidebar from "./Jobsidebar";
import CopyToClipboard from "react-copy-to-clipboard";
import JobSeekerNavbar from "./JobSeekerNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import DownloadSimple from "../Image/DownloadSimple.svg";
import "../styles/jobseeker.css"
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";

function Jobseeker() {
  const token = localStorage.getItem("token");
  const email = JSON.parse(localStorage.getItem("email"));
  const [data, setData] = useState([]);
  const [copyClick, setCopyClick] = useState([]);
  const navigate = useNavigate();
//`${process.env.REACT_APP_BACKEND_BASE_URL}/clients/totalapplicants`
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/clients/totalapplicants`, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("data: ", data.msg)
        setData(data.msg);
      });
  }, []);

  useEffect(()=>{
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/token-expire?email=${email}`, {
     method: 'GET',
     headers: {
       "Authorization": token
     }
    })
    .then((res)=> res.json())
    .then((data)=>{
      console.log("data token",data.msg);
     if(data.msg === "Invalid token. Please login"){
      //  setIsToeknValid(false)
       localStorage.removeItem("token");
       navigate(`/`);

     }else {
      //  setIsToeknValid(true)
     }
   
    })
    .catch((err)=>{
     console.log(err);
    })
 }, [token])


  const handleCopy = (rowIndex) => {
    const newCopyClick = [...copyClick];
    newCopyClick[rowIndex] = true;

    setCopyClick(newCopyClick);

    setTimeout(() => {
      const resetCopyClick = [...newCopyClick];
      resetCopyClick[rowIndex] = false;

      setCopyClick(resetCopyClick);
    }, 2000);
  };

  const downloadResume = (filename, id) => {
    console.log("id", id);
    const fileurl = `${process.env.REACT_APP_BACKEND_BASE_URL}/resumeDownloadseeker/${filename}/${id}`;
    // Implement download logic here
    fetch(fileurl)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, filename);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
   
  };

  return (
    <>
      <div className="MainContainer">
        <div className="mobile-screen">
        <div style={{ height: "70px" }}>
          <JobSeekerNavbar />
        </div>
        <div className="col-1 col-lg-3 col-xl-3 hamburgIcon" style={{marginRight:"60px"}}>
              <Jobsidebar />
            </div>
            </div>

        <hr />
        <div className="container second-main">
          <div className="row">
            <div className="col-1 col-lg-3 col-xl-3 sidebarDiv noclor">
              <Jobsidebar />
            </div>
            <div className="col-lg-9 col-xl-9 " style={{marginLeft:"-40px"}}>
            <div className="container">
            <h5 className="text-left">Applied Jobs <span style={{color:"#9199A3"}}>({data.length})</span> </h5>
            {data.length === 0 ? (
              <p className="text-center mt-4">No data available.</p>
            ) : (
              <div className="db-job-card-table scrollable-container">
                <table>
                  <thead>
                    <tr>
                      <th>Jobs</th>
                      <th>Job Status</th>
                      <th>Resume</th>
                      <th>Application Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((el, rowIndex) => (
                      <tr key={el.RuserID}>
                        <td>
                          <div class="iconbox-content">
                            <div class="post-info2">
                              <div class="post-main-title">
                                <a
                                  href="#"
                                  class="text-gray-900 f-size-16  ft-wt-5"
                                >
                                  {el.position}
                                </a>
                              </div>
                              <div class="body-font-4 text-gray-600 pt-2">
                               
                                <span class="info-tools">{el.city} {el.state}</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="text-success-500 ft-wt-5 d-flex align-items-center">
                            <i class="ph-check-circle f-size-18 mt-1 rt-mr-4">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="CheckCircle">
                                  <path
                                    id="Vector"
                                    d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                                    stroke="#0BA02C"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    id="Vector_2"
                                    d="M13.4375 8.125L8.85414 12.5L6.5625 10.3125"
                                    stroke="#0BA02C"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                              </svg>
                            </i>
                            Active
                          </div>
                        </td>
                        <td>

                          <div class="mt-3 " onClick={() => downloadResume(el.resumeFilename, el.userID)}  style={{cursor:"pointer"}}>
                            <img src={DownloadSimple} alt="" />
                          </div>
                        </td>
                        <td>
                          {el.candidateStatus}
                        </td>
                        <td>
                          <div class="db-job-btn-wrap ">
                            <a
                              href={`/SingleJobPost/${el.jobUniqueID}/b`}
                              className="btn view-button bg-gray-50 text-primary-500 rt-mr-8"
                            >
                              <span class="button-text">View Details</span>
                              {
                                localStorage.setItem("positionTitle", el.position)
                              }
                            </a>
                         
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
            </div>
          </div>
        </div>
        <div class="dashboard-footer text-center body-font-4 text-gray-500">
        Copyright © 2024 Neverhunt All rights reserved.
        </div>
      </div>
    </>
  );
}

export default Jobseeker;