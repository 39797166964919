import React, { useEffect, useState } from 'react';
import JSZip from 'jszip';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import "../styles/Resumepool.css";
import CompanySidebar from './CompanySidebar';
import { useMessage } from './MessageContext';

function CompanyResumepool() {
  const [fileDetails, setFileDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentFile, setCurrentFile] = useState('');
  const [companyID, setCompanyID] = useState('');
  const [resumeParser, setResumeParser] = useState({});
  const [resumeCount, setResumeCount] = useState(0);
  const token = localStorage.getItem("token");
  const { successMessage, setSuccessMessage, processingMessage, setProcessingMessage, clearMessages } = useMessage();
  // clearMessages()
  
  const handleFileUpload = async (event) => {
    setIsLoading(true);
    const files = event.target.files;
    const fileArray = Array.from(files);
    let details = [];
    let totalResumes = 0;

    try {
      for (const file of fileArray) {
        if (file.name.endsWith('.zip')) {
          try {
            const zip = new JSZip();
            const content = await zip.loadAsync(file);
            const zipFiles = Object.keys(content.files);

            for (const filename of zipFiles) {
              if (/\.(pdf|doc|docx)$/i.test(filename)) {
                const sanitizedFilename = filename.replace(/^icedrive-dl-1sarg\//, '');
                setCurrentFile(sanitizedFilename);
                setProcessingMessage(`Processing ${sanitizedFilename}, please wait...`);
                const fileData = await content.files[filename].async('blob');
                const resumeFile = new File([fileData], sanitizedFilename, { type: fileData.type });
                await sendFileDetailsToBackend([resumeFile]);
                await processSingleResume(resumeFile);
                totalResumes++;
                details.push({ name: sanitizedFilename, count: 1 });
              }
            }
          } catch (error) {
            console.error('Error reading ZIP file:', error);
          }
        } else if (/\.(pdf|doc|docx)$/i.test(file.name)) {
          const sanitizedFilename = file.name.replace(/^icedrive-dl-1sarg\//, '');
          setCurrentFile(sanitizedFilename);
          setProcessingMessage(`Processing ${sanitizedFilename}, please wait...`);
          const sanitizedFile = new File([file], sanitizedFilename, { type: file.type });
          await sendFileDetailsToBackend([sanitizedFile]);
          await processSingleResume(sanitizedFile);
          totalResumes++;
          details.push({ name: sanitizedFilename, count: 1 });
        } else {
          details.push({ name: file.name, count: 0 });
        }
      }

      setFileDetails(details);
      setSuccessMessage(`${totalResumes} resumes have been successfully processed`);
    } catch (error) {
      console.error('Error uploading or processing files:', error);
    } finally {
      setIsLoading(false);
      setCurrentFile('');
      setProcessingMessage('');
      setTimeout(() => {
        clearMessages();
      }, 5000);
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/company/getid`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: token
      }
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("data company", data.msg);
        setCompanyID(data.msg);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(()=>{
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/getrescount`, {
      method: "GET",
      headers:{
        Authorization: token
      }
    }).then((res)=> res.json())
    .then((data)=>{
      console.log(data.msg);
      setResumeCount(data.msg.length)
    })
    .catch((err)=>{
      console.log(err.message);
    })
  }, []);

  const processSingleResume = async (file) => {
    try {
      const formdata = new FormData();
      formdata.append("userid", "someUserId");
      formdata.append("resume", file);
      console.log("FormData", file);
      const response = await fetch(`${process.env.REACT_APP_PYTHON_BASE_URL}/process_resume`, {
        method: "POST",
        body: formdata,
      });
       
      const data = await response.json();
      if (data) {
        console.log("response data: ", data);
        const forndata = new FormData();
        forndata.append("parsed_resume", data.parsed_resume);
        forndata.append("parsed_resume1", data.parsed_resume1);
        forndata.append("filename", data.filename);
        forndata.append("companyid", companyID);
        fetch(`${process.env.REACT_APP_PYTHON_BASE_URL}/resumes_attribute_matcher`, {
          method: "POST",
          body: forndata
        })
          .then((res) => res.json())
          .then((data) => {
            console.log("python response", data);
          });
      }
      await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/parsedResume/add`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify({
          parsedResume: data.parsed_resume,
          filename: data.filename
        })
      });

      await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/token/addToken`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          inputToken: data.input_token,
          outputToken: data.output_token,
        })
      });

      setResumeParser(prevState => ({ ...prevState, [file.name]: data.answer }));
    } catch (err) {
      console.error("Error processing resume:", err.message);
    }
  };

  const sendFileDetailsToBackend = async (files) => {
    const formData = new FormData();
    
    files.forEach(file => {
      formData.append('files', file);
    });
    
    formData.append('jobid', 'someJobId');
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/uploadResumes`, {
        method: 'POST',
        headers: {
          Authorization: token,  // Do not include 'Content-Type' here
        },
        body: formData,
      });
      
      if (!response.ok) {
        throw new Error('Failed to upload file details');
      }
      console.log('File details uploaded successfully');
    } catch (error) {
      console.error('Error uploading file details:', error);
    }
  };
  
  
  

  return (
    <div className="MainContainer">
      <div style={{ height: '70px' }}>
        <Navbar />
      </div>
      <hr />
      <div className="container second-main">
        <div className="row">
          <div className="col-1 col-lg-3 sidebarDiv">
            <CompanySidebar />
          </div>
          <div className="col-11 col-lg-9">
            <input
              type="file"
              accept=".pdf,.doc,.docx,.zip"
              multiple
              onChange={handleFileUpload}
              disabled={isLoading}
            />
            <div>
              <h4>Uploaded Files:</h4>
              <ul>
                {fileDetails.map((detail, index) => (
                  <li key={index}>
                    <span>{detail.name}</span>
                    <span>{detail.count} resumes</span>
                  </li>
                ))}
              </ul>
              {processingMessage && (
                <div className="alert alert-info" role="alert">
                  {processingMessage}
                </div>
              )}
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              {isLoading && !currentFile && (
                <div className="alert alert-info" role="alert">
                  Processing files, please wait...
                </div>
              )}
            </div>
            <div>
              <span style={{fontWeight:"500", fontSize:"17px"}}>Total Resumes Count: {resumeCount}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-footer text-center body-font-4 text-gray-500">
        Copyright © 2024 Neverhunt All rights reserved.
      </div>
    </div>
  );
}

export default CompanyResumepool;
