import React, { useState } from 'react';
import AdminSidebar from './AdminSidebar';
import Navbar from './Navbar';
// import '../styles/AdminBright.css';

function AdminBright() {
    const [snapshotData, setSnapshotData] = useState(null);
    const [title, setTitle] = useState(null);

    const handleClick = () => {

            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/file/getData`, {
                method: 'GET',
            })
            .then((res) => res.json())
            .then((data) => {
                console.log("Response from uploadFile", data);
                setSnapshotData(data); // Set snapshotData with the received data
            })
            .catch((error) => {
                console.error('Error uploading LinkedIn URLs:', error);
            });
    };

    const handlechange = (e)=>{
         setTitle(e.target.value);
    }

    return (
        <div className="MainContainer">
            <div style={{ height: "70px" }}>
                <Navbar />
            </div>
            <hr />
            <div className="container second-main">
                <div className="row">
                    <div className="col-1 col-lg-3 sidebarDiv">
                        <AdminSidebar />
                    </div>
                    <div className="col">
                        {/* Get Started Button */}
                        <div className="">
                            <button className="" onClick={handleClick}>
                                Get Started
                            </button>
                            <br />
                            <input type="text" placeholder='enter title' onChange={handlechange}/>
                            <button className="" >
                                search
                            </button>
                        </div>
                        
                        {/* Render the snapshot data if available */}
                        {snapshotData && (
                            <pre>{JSON.stringify(snapshotData, null, 2)}</pre>
                        )}
                    </div>
                </div>
            </div>
            
            <div className="dashboard-footer text-center body-font-4 text-gray-500">
                Copyright © 2024 Neverhunt All rights reserved.
            </div>
        </div>
    );
}

export default AdminBright;
