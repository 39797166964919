import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Responses from './Responses'
import Post from './Post'
import Profile from './Profile'
import Login from '../pages/Login'
import Signup from '../pages/Signup'
import JobPost from '../pages/JobPost'
import ForgotPassword from '../pages/ForgotPassword'
import BookSlot from '../pages/BookSlot'
import ForgetEmail from '../pages/ForgetEmail'
import JobThankyou from '../pages/JobThankyou'
import Singlejob2 from '../pages/SingleJob2'
import SingleJobPost from '../pages/SingleJobPost'
import Jobseeker from './Jobseeker'
import Home from '../pages/Home'
import ResponseDetails from './ResponseDetails'
import JobSekeerProfile from './JobSeekerProfile'
import EmployersDashboard from './Dashbord'
import EmployersDashboardFINAL from './Dashbord'
import KanbanBoard from './KanbanBoard'
import Myjobs from './Myjobs'
import EditJobPost from '../pages/EditJobPost'
import Dashbord from './Dashbord'
import TermsAndConditions from '../pages/TermsAndConditions'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import ErrorBoundary from './ErrorBoundary'
import MyProfileSeeker from './MyProfileSeeker'
import ClientDashbord from './ClientDashboard'
import ClientJobPost from '../pages/ClientJobPost'
import ClientMyjobs from './ClientMyJobs'
import ClientKanbanBoard from './ClientKanban'
import ClientEditJobPost from '../pages/ClientEditJob'
import ClientReq from './ClientReq'
import NotFound from './NotFound'
import SearchResumes from './SearchResumes'
import JobRecommandations from './JobRecommandations'
import AdminSidebar from './AdminSidebar'
import AdminSearchResumes from './AdminSearchResumes'
import AdminPrompts from './AdminPrompts'
import AdminStatus from './AdminStatus'
import Suggestions from './Suggestions'
import Verify from './Verify'
import CompanyDashboard from '../pages/CompanyDashboard'
import Companyusers from './Companyusers'
import Resumepool from './Resumepool'
import AdminToken from './AdminToken'
import CompanyResumepool from './CompanyResumePool'
import CompanySearchResumes from './CompanySearchResumes'
import AdminChat from './AdminChat'
import SeekerMessages from './SeekerMessages'
import CompanyProfile from './CompanyProfile'
import CompanyAdminProfile from './CompanyAdminProfile'
import CompanySementicSearch from './CompanySementicSearch'
import LlmKpi from './LlmKpi'
import Adminfile from './Adminfile'
import AdminBright from './AdminBright'
import AdminPromptsNew from './AdminPromptsNew'


function AllRoutes() {
  return (
    <div>
        <ErrorBoundary>
        <Routes>
          <Route path='/dashboard' element={<Dashbord />}/>
          {/* <Route path='/dashboard' element={<EmployersDashboar />}/> */}
           {/* <Route path='/dashboard' element={<EmployersDashboardFINAL />}/> */}

          <Route path='/responses' element={<Responses />}/>
          <Route path='/posts' element={<Post />}/>
          <Route path='/profile' element={<Profile />}/>
          <Route path='/Jobseeker' element={<Jobseeker />}/>
          <Route path='/suggestions' element={<Suggestions />}/>
          <Route path='/verify/:email' element={<Verify />}/>
          <Route path='/resumepool' element={<Resumepool />}/>

          <Route path='/companyDashboard' element={<CompanyDashboard />}/>
          <Route path='/companyusers' element={<Companyusers />}/>
          <Route path='/companypool' element={<CompanyResumepool />}/>
          <Route path='/companySearch' element={<CompanySearchResumes />}/>
          <Route path='/companyprofile' element={<CompanyProfile />}/>
          <Route path='/companyadminprofile' element={<CompanyAdminProfile />}/>
          <Route path='/companysementicsearch' element={<CompanySementicSearch />}/>


          <Route path='/AdminStatus' element={<AdminStatus />}/>
          
          {/* search internal resumes  */}
          <Route path='/search-resumes' element={<SearchResumes />}/>

            {/* Cleint side routes  */}
          <Route path='/ClientDashboard' element={<ClientDashbord />}/>
          <Route path='/ClientJobPost' element={<ClientJobPost />}/>
          <Route path='/ClientMyjobs' element={<ClientMyjobs />}/>
          <Route path='/ClientKanban/:id' element={<ClientKanbanBoard />}/>
          <Route path='/ClientEditJobPost/:id' element={<ClientEditJobPost />}/>

          {/* admin routes */}
          <Route path='/Adminsidebar' element={<AdminSidebar />}/>
          <Route path='/AdminDash' element={<AdminSearchResumes />}/>
          <Route path='/Adminprompts' element={<AdminPrompts />}/>
          <Route path='/Adminpromptsnew' element={<AdminPromptsNew />}/>
          <Route path='/Adminchat' element={<AdminChat />}/>
          <Route path='/token' element={<AdminToken />}/>
          <Route path='/llmkpi' element={<LlmKpi />}/>
          <Route path='/file' element={<Adminfile />}/>
          <Route path='/fileProcess' element={<AdminBright />}/>

           <Route path='/' element={<Home />} />
           <Route path='/jobseeker/profile' element={<JobSekeerProfile />}/>
           <Route path='/ClientReq' element={<ClientReq />}/>
           <Route path='/alljobs' element={<JobRecommandations />}/>
           <Route path='/Login' element={<Login/>}/>
            <Route path='/Signup' element={<Signup />} />
            <Route path='/JobPost' element={<JobPost />} />
            <Route path='/SingleJobPost/:id/:name' element={<SingleJobPost />} />
            <Route path='/ForgotPassword/:token' element={<ForgotPassword />} />
            <Route path='/BookSlot/:uniqueID' element={<BookSlot />} />
            <Route path='/ForgetEmail' element={<ForgetEmail />} />
            <Route path='/JobThankyou/:uniqueID' element={<JobThankyou />} />
            <Route path='/SingleJob2/:id' element={<Singlejob2 />}/> 
            <Route exact path="/response/:jobUniqueID/:userID" element={<ResponseDetails/>}/>
            <Route path='/kanban/:id' element={<KanbanBoard />}/> 
            <Route path='/myjobs' element={<Myjobs />}/> 
            <Route path='/myprofile' element={<MyProfileSeeker />}/> 
            <Route path='/editJobPost/:id' element={<EditJobPost />}/> 
            <Route path='/terms' element={<TermsAndConditions />} />
            <Route path='/privacypolicy' element={<PrivacyPolicy />} />
            <Route path='/seekerMessages' element={<SeekerMessages />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
        </ErrorBoundary>
       
    </div>
  )
}

export default AllRoutes