import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./SingleJobPost.css";
import JobSeekerNavbar from "../components/JobSeekerNavbar";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Modal from "react-modal";

const Check = () => {
  const [jobData, setJobData] = useState("");
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const jobDescriptionHTML = { __html: jobData.jobDescription };
  const [daysPosted, setDaysPosted] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [email, setEmail] = useState("");
  const [termsConditionsChecked, setTermsConditionsChecked] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [randomPassword, setRandomPassword] = useState("");
  const [parsedJD, setParsedJD] = useState("");
  const [message, setMessage] = useState("");

  const { id } = useParams();
  console.log("parsms id", id);
  const pathSegment = window.location.pathname.split("/").pop();
  console.log("last path seg", pathSegment);

  // - https://neverhuntbe.apps.neverhunt.io/jobs/onejob/${id}

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/onejob/${id}`)
      .then((res) => res.json())
      .then((data) => {
        console.log("jobData", data.msg);
        setJobData(data.msg);
        setParsedJD(data.msg.parsedjd);
        console.log("parsedJD", data.msg.parsedjd);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  localStorage.setItem("position", jobData.position);
  localStorage.setItem("jobID", JSON.stringify(id));
  const secondApply = JSON.parse(localStorage.getItem(`${id}`));
  console.log("Second", secondApply);
  const role = JSON.parse(localStorage.getItem("role"));
  console.log("role: " + role);

  const handleTermsConditionsChange = (e) => {
    setTermsConditionsChecked(e.target.checked);
  };

  const locEmail = JSON.parse(localStorage.getItem("email"));
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/user/token-expire?email=${locEmail}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.msg === "Invalid token. Please login") {
          setIsTokenValid(false);
          localStorage.removeItem("token");
        } else {
          setIsTokenValid(true);
        }
        console.log("data token", data.msg);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  useEffect(() => {
    const createdAt = `${jobData.createdAt}`;

    const createdAtDate = new Date(createdAt);

    const currentDate = new Date();

    const timeDifference = currentDate - createdAtDate;

    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference === 0) {
      setDaysPosted("today");
    } else if (daysDifference === 1) {
      setDaysPosted("1 day ago");
    } else {
      setDaysPosted(`${daysDifference} days ago`);
    }
  }, [jobData]);

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleModel = () => {
    if (token) {
      navigate(`/BookSlot/${id}`);
    } else {
      setShowModal(true);
    }
  };

  const generatePassword = () => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
    let newPassword = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      newPassword += charset.charAt(Math.floor(Math.random() * n));
    }
    return newPassword;
  };

  const handleSubmit = async () => {
    var seekerid = "";
    let obj = {
      email,
      password: generatePassword(),
      role: "jobseeker",
      mode: "Apply",
      jobLink: `https://neverhunt.ai/SingleJobPost/${id}/b`,
      position: `${jobData.position}`,
      jobUniqueID: id,
    };

    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/register`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((data) => {
        seekerid = data.content._id;
        console.log("data of register", data);
        console.log("seekerid check", seekerid);

        const formDatafile = new FormData();
        formDatafile.append("files", file);
        formDatafile.append("jobid", "someJobId");
        formDatafile.append("sid", data.content._id);

        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/uploadResumes`, {
          method: "POST",
          headers:{
            Authorization: token,
          },
          body: formDatafile,
        })
          .then((res) => res.json())
          .then((data) => {
            console.log("data", data.msg);
          });
       
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/uploadResumesSeeker`, {
          method: "POST",
    
          body: formDatafile,
        })
          .then((res) => res.json())
          .then((data) => {
            console.log("data", data.msg);
          });
      })
      .catch((err) => {
        console.log(err.message);
      });

  

    console.log("about to call uploadResumes");
  

    console.log("File details uploaded successfully");

    // Handle the form submission logic here
    const formData = new FormData();
    formData.append("jobdesc", parsedJD);
    formData.append("resumes", file);

    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/applyjob/secondApply`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        jobUniqueID: id,
        email: email,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("data check for second " + data.msg);
        if (data.msg === "Already Applied") {
          setMessage("Already Applied");
          return;
        } else {
          setTimeout(async () => {
            setMessage("Processing...");
            const response = await fetch(
              "https://neverhunt-python.apps.neverhunt.io/get_resume",
              {
                method: "POST",
                body: formData,
              }
            );
            const data = await response.json();
            if (data.answer && data.answer.length > 0) {
              console.log("dataAnswer: ", data.answer);

              const updatedAnswer = data.answer.map((item) => ({
                ...item,
                result_mode: "Apply",
              }));

              // fetch request to save the results
              fetch(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/jobs/ResumeResults`,
                {
                  method: "POST",
                  headers: {
                    "content-type": "application/json",
                  },
                  body: JSON.stringify({
                    uniqueJobID: id,
                    matchingData: updatedAnswer,
                    masterMatchingData: updatedAnswer,
                  }),
                }
              )
                .then((res) => res.json())
                .then((data) => {
                  setMessage("processed");
                  setShowModal(true); // Show the modal
                  setTimeout(() => {
                    setMessage("");
                  }, 3000);
                  console.log("saved successfully", data.msg);
                });
            }
          }, 500);

          //`${process.env.REACT_APP_BACKEND_BASE_URL}/user/register`

          //fetch req to save the job
          fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/applyjob/jobform`, {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              jobUniqueID: id,
              email: email,
              position: jobData.position,
              resumeFilename: file.name,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              console.log(data.msg);
            })
            .catch((err) => {
              console.log(err.message);
            });
        }
        console.log(data.msg);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const closeModal = () => {
    setShowModal(false);
    navigate("/");
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <JobSeekerNavbar />
      <div className="">
        <div className="card">
          <div className="jobDescription">
            <div
              className="breadcrumbJob container"
              style={{ paddingLeft: "40px" }}
            >
              <div className="breadcrumb-menu jdtop">
                <h6 className="f-size-18">Job Description</h6>
              </div>
            </div>
          </div>
          <div className="topname card-body container mx-auto custom-card-body ">
            <br />
          </div>
          <div
            className="maininfo card-body container mx-auto custom-card-body"
            style={{ marginTop: "-50px" }}
          >
            <div
              className="card-body container custom-card-body leftbox"
              style={{ marginTop: "-2px" }}
            >
              <p className="card-text job-description">
                <strong
                  style={{
                    fontWeight: "500",
                    fontSize: "20px",
                    marginBottom: "200px",
                  }}
                ></strong>
                <div className="logo-container">
                  <div className="posted-by">
                    Posted By
                    <img
                      src={`${process.env.REACT_APP_BACKEND_BASE_URL}/Logo/${jobData?.logo}`}
                      alt="logo"
                      className="logo-image"
                    />
                  </div>
                </div>

                <div
                  dangerouslySetInnerHTML={jobDescriptionHTML}
                  style={{ marginTop: "10px" }}
                />
              </p>
            </div>
            <div
              className="infoRight"
              style={{ width: "35%", borderLeft: "1px solid #eaeaea" }}
            >
              <div>
                <div className="tw-px-8 tw-pb-6 tw-pt-8 rightbox">
                  <div className="tw-px-2 tw-pt-6 tw-pb-8 border mb-4">
                    <p style={{ textAlign: "center", color: "blue" }}>
                      {message}
                    </p>
                    <h2
                      className="tw-text-[#18191C] tw-text-lg tw-font-medium tw-mb-2"
                      style={{ paddingLeft: "14px" }}
                    >
                      Enter Email and Upload Resume
                    </h2>
                    <div className="tw-w-full tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md">
                      <input
                        type="email"
                        className="tw-w-full tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md mb-2"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <input
                        type="file"
                        className="tw-w-full tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md mb-2"
                        style={{ marginLeft: "-1px" }}
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                      {pathSegment === "b" ? (
                        <button
                          className="tw-w-full tw-px-4 tw-py-2 tw-bg-gray-500 tw-text-white tw-rounded-md"
                          style={{ cursor: "not-allowed" }}
                          disabled
                        >
                          Submitted
                        </button>
                      ) : (
                        <button
                          className="tw-w-full tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white tw-rounded-md"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="tw-share-area tw-px-8 tw-pt-6 tw-pb-8 border mb-4">
                    <h2 className="tw-text-[#18191C] tw-text-lg tw-font-medium tw-mb-2">
                      Share
                    </h2>
                    <ul className="tw-list-none tw-flex tw-items-center tw-gap-2 tw-p-0 tw-m-0 tw-mb-6">
                      <li className="tw-text-[#0A65CC] hover:tw-bg-[#0A65CC] tw-cursor-pointer hover:tw-text-white tw-flex tw-gap-1.5 tw-items-center tw-text-base tw-font-medium tw-bg-[#E7F0FA] tw-px-4 tw-py-2 tw-rounded-[4px]">
                        <span>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.81787 15.1813L15.1818 8.81738"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M13.5904 16.7731L10.9388 19.4248C10.0948 20.2685 8.95028 20.7424 7.75694 20.7423C6.5636 20.7422 5.41916 20.2681 4.57534 19.4242C3.73152 18.5804 3.25743 17.436 3.25732 16.2426C3.25722 15.0493 3.73112 13.9048 4.5748 13.0608L7.22645 10.4092"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M16.7727 13.5899L19.4243 10.9383C20.268 10.0943 20.7419 8.94979 20.7418 7.75645C20.7417 6.56311 20.2676 5.41867 19.4238 4.57486C18.5799 3.73104 17.4355 3.25694 16.2422 3.25684C15.0488 3.25673 13.9043 3.73064 13.0603 4.57431L10.4087 7.22596"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </span>
                        <CopyToClipboard
                          text={`https://stg.neverhunt.ai/SingleJobPost/${id}/a`}
                          onCopy={handleCopy}
                        >
                          {isCopied ? (
                            <span>Copied</span>
                          ) : (
                            <span>Copy Link</span>
                          )}
                        </CopyToClipboard>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="row belowJob"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginLeft: "-135px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="col-xl-4 col-lg-6 col-md-6 col-sm-4 rt-mb-32 "
                      style={{ marginTop: "-8px" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-footer text-center body-font-4 text-gray-500 copyFooter">
          Copyright © 2024 Neverhunt All rights reserved.
        </div>
      </div>
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          },
        }}
      >
        <h2>Resume has been submitted</h2>
        <p>A notification email has been sent to your email address.</p>
        <button onClick={closeModal} style={{ marginTop: "20px" }}>
          Close
        </button>
      </Modal>
    </div>
  );
};

export default Check;
